import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import { Link } from "gatsby"

export default () => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="Locations | RWK Attorneys" defer={false} />

		<div className="container">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><Link to="/">Home</Link></li>
					<li class="breadcrumb-item"><Link to="/about">About</Link></li>
					<li class="breadcrumb-item active" aria-current="page">Locations</li>
				</ol>
			</nav>

			<h1>Locations</h1>
		</div>
	</Layout>
)